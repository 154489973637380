<template>
  <v-form @submit.prevent="submitResponse">
    {{ randomNumber }}
  </v-form>
</template>

<script>
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import {seededRandomBetween} from '@/courses/utils/seededShuffle';

export default {
  name: 'SeededRandomDemoQuestion',
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        changeInBoilingPoint: null,
        boilingPoint: null,
      },
    };
  },
  computed: {
    seed() {
      return this.$gate.user?.id ?? 1;
    },
    randomNumber() {
      return seededRandomBetween(this.seed, 5.5, 9.5, 1);
    },
  },
};
</script>

<style scoped></style>
